@value small from '../../styles/Breakpoints.module.css';

.root {
  margin-top: 1.6875rem;
}

:global(.MuiTypography-h2) {
  font-size: 1.875rem;
  font-weight: 300;
  line-height: 2.0625rem;
}

@media (small) {
  :global(.MuiTypography-h2) {
    font-size: 1.5rem;
  }
}
