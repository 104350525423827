@value light-blue from '../../../styles/Colours.module.css';
@value talp-teal from '../../../styles/Colours.module.css';

.Button > :global(.MuiButton-containedPrimary) {
  background-color: talp-teal;
  width: 6rem;
}

.Button > :global(.MuiButton-root) {
  text-transform: none;
  font-weight: 300;
}

.Button > :global(.Mui-disabled) {
  background-color: rgba(0, 0, 0, 0.26);
}
