/* Reset all margins to ensure consistency in css */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

li,
ul {
  list-style-type: none;
}

body {
  overflow: scroll; /* Show scrollbars all the time to avoid the elements jumping around when it appears */
  background-color: whitesmoke;
}

a {
  color: #00619e;
}

a:hover {
  color: #004777;
  text-decoration-thickness: max(3px);
}
