@value talp-slate-gray from './styles/Colours.module.css';
@value talp-white from './styles/Colours.module.css';

.App {
  display: grid;
  grid-template-columns: minmax(0, auto) min(1000px, 100vw) minmax(0, auto);
  grid-template-rows: auto;
}

.App-header {
  grid-row: 1;
  grid-column: 1/4;
  height: 36px;
  background-color: talp-slate-gray;
}

.JiscLogo {
  height: 36px;
  width: height;
}

.AppBody {
  background-color: talp-white;
  grid-row: 2;
  grid-column: 2;
  padding-left: 20px;
  padding-right: 20px;
  height: fit-content;
  min-height: 100vh;
  padding-bottom: 20rem;
}

.Title {
  margin-top: 2rem;
}

.ToggleButtons {
  margin-top: 2rem;
}

.SearchComponent {
  margin-top: 5rem;
  margin-left: 2rem;
}
