@value small from '../../styles/Breakpoints.module.css';

.root {
  margin-top: 1.6875rem;
}

:global(.MuiTypography-h1) {
  font-size: 2.8125rem;
  font-weight: 300;
}

@media (small) {
  :global(.MuiTypography-h1) {
    font-size: 1.875rem;
  }
}
