@value talp-teal from '../../styles/Colours.module.css';

.Form {
  display: grid;
  grid-template-columns: 25rem 6rem;
  grid-template-rows: auto;
}

.Button {
  display: inline-block;
  grid-row: 1;
  grid-column: 2;
  margin-left: 0.5rem;
}

.CheckBoxAndLabel {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  align-items: center;
  justify-self: end;
}

.CheckBoxAndLabel p {
  margin: 0;
}

.CheckBox {
  padding: 0;
  margin-left: 0.2rem;
  margin-right: -3px; /* Make Checkbox line up with above form */
}

.ListContainer {
  margin-top: 1rem;
}
