.body1 {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.6875rem;
}

.inline {
  display: inline;
}

.bold {
  font-weight: 400;
}
