@value talp-baby-blue from '../../../styles/Colours.module.css';

.Accordion {
  width: min(800px, 100%);
  background-color: talp-baby-blue;
  margin-top: 0.5rem;
  height: fit-content;
}

.AccordionLegend {
  width: min(800px, 100%);
  margin-top: 0.5rem;
}

.AccordionHeadingContainer {
  display: grid;
  min-height: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.publisherGridColumns {
  grid-template-columns: 10fr 1fr 1fr 1fr 2fr;
}

.journalGridColumns {
  grid-template-columns: 1fr 1fr 1fr 1fr 0.2fr;
}

.AccordionHeading {
  margin: auto;
  grid-row: 1;
  text-align: center;
}

.AccordionHeading:first-of-type {
  margin: auto;
  margin-left: 1rem;
  margin-right: 0;
  grid-row: 1;
  text-align: left;
}

.Col1 {
  grid-column: 1;
}

.Col2 {
  grid-column: 2;
}

.Col3 {
  grid-column: 3;
}

.Col4 {
  grid-column: 4;
}

.Col5 {
  grid-column: 5;
}

.AccordionIcon {
  grid-row: 1;
  justify-self: right;
  margin: auto;
  margin-left: 0;
  margin-right: 1rem;
  padding: 0;
}

.AccordionBody {
  padding-left: 1rem;
  background-color: talp-baby-blue;
  margin-bottom: 0.5rem;
  height: fit-content;
  transition: height 0.25s;
}

.Accordion .Open {
  height: fit-content;
  padding: 1rem;
}
