@value talp-white from '../../../styles/Colours.module.css';
@value faded-gray from '../../../styles/Colours.module.css';

.Autocomplete > :global(.react-select-autocomplete__control) {
  border-radius: 0;
  background-color: talp-white;
  cursor: text;
}

.Autocomplete :global(.react-select-autocomplete__placeholder) {
  color: faded-gray;
}
